body {
    background-color: #f4f7f6;
    /* margin-top:20px; */
}

.mylogincard {
    max-width: 780px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 0 auto;
    float: none;
    margin-bottom: 10px;
}

/* .modal-tall .modal-dialog {
    height: 80%;
}

.modal-tall .modal-content {
    height: 100%;
}

.modal-tall .modal-body {
    height: 140%;
} */


/* .myPersonalInfo{
    max-width: 18rem;
} */

.more-text-format {
    text-decoration: none;
}

.profileSideNavbarContainer {
    height: 100%;
    /* width: 200px; */
    /* background-color: #ff0; */
    /* position: fixed !important; */
    z-index: 1;
    overflow: auto;
    padding: 10px;
}

.buttonProfile {
    width: 250px;
    height: 150px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* ul .userProfile{
    list-style-type: none;
    margin: 0;
    padding: 0;
} */

.footer {
    bottom: 0;
    position: relative;
    background-color: cornflowerblue;
    padding: 10px;
    width: 100%;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(111, 111, 111, 0.5);
    z-index: 8;
}

.card {
    background: #fff;
    transition: .5s;
    border: 0;
    margin-bottom: 30px;
    border-radius: .55rem;
    position: relative;
    width: 100%;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
}

.chat-app .people-list {
    width: 280px;
    position: absolute;
    left: 0;
    top: 0;
    padding: 20px;
    z-index: 7
}

.chat-app .chat {
    margin-left: 280px;
    border-left: 1px solid #eaeaea
}

.people-list {
    -moz-transition: .5s;
    -o-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s
}

.people-list .chat-list li {
    padding: 10px 15px;
    list-style: none;
    border-radius: 3px
}

.people-list .chat-list li:hover {
    background: #efefef;
    cursor: pointer
}

.people-list .chat-list li.active {
    background: #efefef
}

.people-list .chat-list li .name {
    font-size: 15px
}

.people-list .chat-list img {
    width: 45px;
    border-radius: 50%
}

.people-list img {
    float: left;
    border-radius: 50%
}

.people-list .about {
    float: left;
    padding-left: 8px
}

.people-list .status {
    color: #999;
    font-size: 13px
}

.chat .chat-header {
    padding: 15px 20px;
    border-bottom: 2px solid #f4f7f6
}

.chat .chat-header img {
    float: left;
    border-radius: 40px;
    width: 40px
}

.chat .chat-header .chat-about {
    float: left;
    padding-left: 10px
}

.chat .chat-history {
    padding: 20px;
    border-bottom: 2px solid #fff;
    height: 350px;
    overflow-x: auto
}

.chat .chat-history ul {
    padding: 0
}

.chat .chat-history ul li {
    list-style: none;
    margin-bottom: 30px
}

.chat .chat-history ul li:last-child {
    margin-bottom: 0px
}

.chat .chat-history .message-data {
    margin-bottom: 15px
}

.chat .chat-history .message-data img {
    border-radius: 40px;
    width: 40px
}

.chat .chat-history .message-data-time {
    color: #434651;
    padding-left: 6px
}

.chat .chat-history .message {
    color: #444;
    padding: 18px 20px;
    line-height: 26px;
    font-size: 16px;
    border-radius: 7px;
    display: inline-block;
    position: relative
}

.chat .chat-history .message:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px
}

.chat .chat-history .my-message {
    background: #efefef
}

.chat .chat-history .my-message:after {
    bottom: 100%;
    left: 30px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #efefef;
    border-width: 10px;
    margin-left: -10px
}

.chat .chat-history .other-message {
    background: #e8f1f3;
    text-align: right
}

.chat .chat-history .other-message:after {
    border-bottom-color: #e8f1f3;
    left: 93%
}

.chat .chat-message {
    padding: 20px
}

.online,
.offline,
.me {
    margin-right: 2px;
    font-size: 8px;
    vertical-align: middle
}

.online {
    color: #86c541
}

.offline {
    color: #e47297
}

.me {
    color: #1d8ecd
}

.float-right {
    float: right
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0
}

@media only screen and (max-width: 767px) {
    .chat-app .people-list {
        height: 465px;
        width: 100%;
        overflow-x: auto;
        background: #fff;
        left: -400px;
        display: none
    }

    .chat-app .people-list.open {
        left: 0
    }

    .chat-app .chat {
        margin: 0
    }

    .chat-app .chat .chat-header {
        border-radius: 0.55rem 0.55rem 0 0
    }

    .chat-app .chat-history {
        height: 300px;
        overflow-x: auto
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .chat-app .chat-list {
        height: 650px;
        overflow-x: auto
    }

    .chat-app .chat-history {
        height: 600px;
        overflow-x: auto
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .chat-app .chat-list {
        height: 480px;
        overflow-x: auto
    }

    .chat-app .chat-history {
        height: calc(100vh - 350px);
        overflow-x: auto
    }
}

.listGroupItem:hover {
    cursor: pointer;
    background-color: #efefef;
}

.menu-item-social {
    padding: 8px;
}

.menu-item-social:hover {
    cursor: pointer;
    background-color: #efefef;
    /* padding: 5px; */
    border-radius: 5px;
}

.card-title {
    color: #232429
}

.card-title:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #0d6efd;
}

.card-like-comments {
    color: #858181;
}

.card-like-comments:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #0d6efd;
}

.input::placeholder {
    font-size: 11px;
}

.lov-content {
    z-index: 10;
    position: absolute;
    width: 30%;
    max-width: 380px;
    height: 200px;
    overflow-y: auto;
    box-shadow: 0px 0px 4px #888;
    background-color: #fff;
    border-radius: 5px;
}

.lov-list-element {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
}

.lov-list-element:hover {
    cursor: pointer;
    background-color: #6d6c6c;
    color: #fff;
    border-radius: 0px;
    /* padding: 2; */
}

@media (max-width: 768px) {
    .lov-content {
        width: 90%;
        /* max-width: 30%; */
        /* Change width for screens up to 768px width */
    }
}

@media (max-width: 540px) {
    .lov-content {
        /* width: 90%; */
        max-width: 100%;
        /* Change width for screens up to 768px width */
    }
}

@media (min-width: 1024px) {
    .lov-content {
        width: 30%;
        /* Change width for screens larger than 1024px width */
    }
}

.custom-label {
    font-size: 14px; /* Your desired font size */
}